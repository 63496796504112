import React from "react";
import theme from "theme";
import { Theme, Text, Strong, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
		</Helmet>
		<Components.QuarklycommunityKitCookieUsed background="--color-darkL1" />
		<Components.HeaderNew>
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="quarklycommunityKitMenuOverride5" />
		</Components.HeaderNew>
		<Section padding="120px 0 120px 0" background="linear-gradient(340deg,rgba(163, 103, 177, 0.29) 1%,rgba(57, 0, 0, 0.81) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/istockphoto-1191395009-612x612.jpg?v=2024-06-20T19:52:03.656Z) bottom/cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Headline-3">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Text margin="0px 0px 20px 0px" font="normal 700 52px/1.2 --fontFamily-sans" color="--light" sm-font="normal 700 42px/1.2 --fontFamily-sans">
				Ми раді вітати вас на сайті "StarGaze Events"
			</Text>
			<Text
				margin="0px 50% 40px 0px"
				font="--lead"
				color="--light"
				lg-margin="0px 20% 50px 0px"
				md-margin="0px 0 50px 0px"
				border-color="--color-lightD1"
			>
				Наша івент-агенція спеціалізується на організації заходів будь-якої складності та масштабу. Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність.
			</Text>
			<Box
				min-width="10px"
				min-height="10px"
				display="grid"
				grid-template-columns="repeat(4, 1fr)"
				grid-gap="36px 34px"
				md-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
			>
				<Box min-width="10px" min-height="10px">
					<Text margin="0px 0px 10px 0px" font="normal 600 30px/1.2 --fontFamily-sans" color="--light">
						12+
					</Text>
					<Text margin="0px 0px 0px 0px" color="#c5cdd7" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" border-color="#EDF2F6">
						<Strong color="--lightD1">
							років досвіду
						</Strong>
					</Text>
				</Box>
				<Box min-width="10px" min-height="10px">
					<Text margin="0px 0px 10px 0px" font="normal 600 30px/1.2 --fontFamily-sans" color="--light">
						450+
					</Text>
					<Text margin="0px 0px 0px 0px" color="#c5cdd7" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						<Strong color="--lightD1">
							успішних подій
						</Strong>
					</Text>
				</Box>
				<Box min-width="10px" min-height="10px">
					<Text margin="0px 0px 10px 0px" font="normal 600 30px/1.2 --fontFamily-sans" color="--light">
						700+
					</Text>
					<Text margin="0px 0px 0px 0px" color="#c5cdd7" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						<Strong color="--lightD1">
							щасливих клієнтів
						</Strong>
					</Text>
				</Box>
				<Box min-width="10px" min-height="10px">
					<Text margin="0px 0px 10px 0px" font="normal 600 30px/1.2 --fontFamily-sans" color="--light">
						40+
					</Text>
					<Text margin="0px 0px 0px 0px" color="#c5cdd7" font="normal 300 18px/1.5 --fontFamily-sansHelvetica">
						<Strong color="--lightD1">
							локацій
						</Strong>
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 120px 0" background="#ffffff" quarkly-title="Team-15">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(1, 1fr)"
				grid-template-rows="repeat(2, 1fr)"
				grid-gap="46px 24px"
				md-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-rows="auto"
				sm-grid-template-columns="1fr"
				flex-direction="column"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline3">
						<Strong font="--headline2">
							Наші послуги
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						StarGaze Events пропонує повний спектр послуг з організації заходів: від корпоративних свят і весіль до дитячих днів народжень і приватних вечірок. Ми забезпечуємо повний цикл підготовки, включаючи планування, декорування, технічне забезпечення та координацію заходу. Наша команда професіоналів зробить все можливе, щоб ваш захід пройшов бездоганно.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19:52:03.705Z"
						display="block"
						width="100%"
						height="300px"
						object-fit="cover"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						border-radius="50px"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19%3A52%3A03.705Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19%3A52%3A03.705Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19%3A52%3A03.705Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19%3A52%3A03.705Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19%3A52%3A03.705Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19%3A52%3A03.705Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event_organization_agency_london_event-min.png?v=2024-06-20T19%3A52%3A03.705Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline3">
						<Strong font="--headline2">
							Професійний підхід
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						Наша команда складається з досвідчених фахівців, які мають великий досвід у сфері організації заходів. Ми дбаємо про кожну деталь, щоб ваш захід був унікальним та неповторним. Від першої консультації до завершення заходу ми підтримуємо вас на кожному етапі, забезпечуючи високий рівень обслуговування.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19:52:03.667Z"
						display="block"
						width="100%"
						height="300px"
						object-fit="cover"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						border-radius="50px"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Events4.jpg?v=2024-06-20T19%3A52%3A03.667Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline2">
						<Strong>
							Індивідуальний підхід до кожного клієнта
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						Ми розуміємо, що кожен захід – це унікальна подія, яка вимагає індивідуального підходу. Ми уважно вислуховуємо ваші побажання та потреби, щоб створити захід, який повністю відповідає вашим очікуванням. Наша мета – зробити ваш день особливим та незабутнім.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19:52:03.658Z"
						display="block"
						width="100%"
						height="300px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						border-radius="50px"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19%3A52%3A03.658Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19%3A52%3A03.658Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19%3A52%3A03.658Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19%3A52%3A03.658Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19%3A52%3A03.658Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19%3A52%3A03.658Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19%3A52%3A03.658Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline2">
						<Strong>
							Креативність та інновації
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						Ми постійно стежимо за новими трендами у сфері організації заходів та застосовуємо найсучасніші технології та ідеї. Креативність і нестандартний підхід дозволяють нам створювати унікальні концепції та незабутні шоу-програми. Ваш захід буде не лише якісним, але й яскравим та оригінальним.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19:52:03.650Z"
						display="block"
						width="100%"
						height="300px"
						object-fit="cover"
						margin="0px 0px 30px 0px"
						sm-height="350px"
						border-radius="50px"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/event-manager-showing-laptop-young-woman_23-2148085287.jpg?v=2024-06-20T19%3A52%3A03.650Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Text margin="0px 0px 15px 0px" color="--dark" font="--headline2">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Партнери та підрядники
						</Strong>
						{"\n"}
					</Text>
					<Text margin="0px 0px 0px 0px" color="--dark" font="--base">
						Ми співпрацюємо з найкращими підрядниками та постачальниками, що дозволяє нам забезпечувати високий рівень послуг. Наші партнери – це професіонали своєї справи, які гарантують якість та надійність у всіх аспектах організації заходів. Ваша довіра для нас – найвища нагорода.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.NewFooter1>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="link2" />
		</Components.NewFooter1>
	</Theme>;
});